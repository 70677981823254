import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SwapOutlined,
  DeleteOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import moment from "moment";
import Progress from "react-progress-2";
import { GetDataAuth } from "../../apiService/GetData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import useAllFunds from "../../hooks/useAllFunds";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

const { confirm } = Modal;

function FundsDataTable() {
  const navigate = useNavigate();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    lang_id: "en",
    dataTable: true,
  });

  const allFunds = useAllFunds(filterData);

  const columns = [
    {
      title: "Fund Name",
      dataIndex: "title",
      width: 150,
    },
    {
      title: "Fund Slug",
      dataIndex: "slug",
      width: 150,
    },
    {
      title: "Fund Description",
      render: (record) => parse(record.description.substring(0, 120) + "..."),
      width: 200,
    },
    {
      title: "Fund Amount",
      dataIndex: "fund_amount",
      width: 100,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 150,
    },
    {
      title: "Language Code",
      dataIndex: "lang_id",
      width: 80,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={record.status === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
      fixed: "right",
      width: 50,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Price Management">
            <Button
              className="view_button"
              shape="circle"
              icon={<SnippetsOutlined />}
              onClick={() =>
                navigate(
                  `/fund-management/unit-price-management/${record.slug}`
                )
              }
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Edit Fund">
            <Button
              className="view_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() =>
                navigate(
                  `/fund-management/edit/${record.lang_id}/${record.slug}`
                )
              }
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Multi Language">
            <Button
              className="edit_button"
              shape="circle"
              icon={<SwapOutlined />}
              onClick={() =>
                navigate(`/fund-management/multi-language/${record.slug}`)
              }
            />
          </Tooltip>
          &nbsp;&nbsp;
          {record.status === 1 ? (
            <Tooltip placement="bottom" title="Deactivate Fund">
              <Button
                className="delete_button"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => statusChange(record.slug, "Inactive")}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="Activate Fund">
              <Button
                className="view_button"
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => statusChange(record.slug, "Active")}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Delete News">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteFundContent(record.slug)}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 140,
      align: "right",
    },
  ];

  const statusChange = (slug, type) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${type} this fund?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.FUND_STATUS_CHANGE}/${slug}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteFundContent = (slug) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this content?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.FUND_DELETE}/${slug}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <>
      <Table
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={allFunds.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allFunds.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default FundsDataTable;
