import { Button, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis, ckeditorConfig, IMAGE_PATH } from "../../properties";
import Progress from "react-progress-2";
import useLangs from "../../hooks/useLangs";
import { LeftOutlined } from "@ant-design/icons";
import useFundDetails from "../../hooks/useFundDetails";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { Option } = Select;

function EditFund() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [desData, setDesData] = useState();
  const [expertDesData, setExpertDesData] = useState();
  const [selectedFile, setSelectedFile] = useState();

  useBreadCrumb("Edit fund", location.pathname, "", "add");

  const langs = useLangs();

  const [filterData] = useState({
    lang_id: params.lang_id,
    slug: params.slug,
  });

  const fundDetails = useFundDetails(filterData);

  useEffect(() => {
    if (fundDetails) {
      form.setFieldsValue({
        title: fundDetails.title,
        slug: fundDetails.slug,
        lang_id: fundDetails.lang_id,
        description: fundDetails.description,
        fund_amount: fundDetails.fund_amount,
        email_address: fundDetails.email_address,
        expert_name: fundDetails.expert_name,
        box_color: fundDetails.box_color,
        box_title: fundDetails.box_title,
      });
      setDesData(fundDetails?.description || "");
      setExpertDesData(fundDetails?.expert_description || "");
    }
    // eslint-disable-next-line
  }, [fundDetails]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      const body = new FormData();
      body.append("title", values.title);
      body.append("slug", values.slug);
      body.append("lang_id", values.lang_id);
      body.append("box_color", values.box_color);
      body.append("box_title", values.box_title);
      body.append("expert_name", values.expert_name);
      body.append("email_address", values.email_address);
      body.append("fund_amount", values.fund_amount);
      body.append("expert_description", expertDesData);
      body.append("description", desData);
      body.append("img_url", selectedFile ? selectedFile : false);
      Progress.show();
      PostDataAuth(apis.FUND_UPDATE, body).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            body.append("location", "cdeditor-images");
            fetch(`${apis.CKEDITOR_UPLOAD}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${IMAGE_PATH}/${res.data.file_path}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const uploadFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Edit fund</p>
        </div>

        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <Form.Item
            name="title"
            label="Fund Title"
            rules={[
              {
                required: true,
                message: "Title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Fund title" />
          </Form.Item>
          <Form.Item
            name="slug"
            label="Fund slug"
            rules={[{ required: true, message: "Slug cannot be empty!" }]}
          >
            <Input type="text" size="large" placeholder="slug" disabled />
          </Form.Item>

          <Form.Item
            name="lang_id"
            label="Language"
            required
            rules={[
              {
                required: true,
                message: "Please select language!",
              },
            ]}
          >
            <Select size="large" placeholder="Select language" disabled>
              {langs.map((item, index) => (
                <Option key={index} value={item.lang}>
                  {item.description}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="description" label="Fund Description">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "Content description",
                toolbar: ckeditorConfig.toolbar,
              }}
              editor={ClassicEditor}
              data={desData}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "z-index",
                    "999999 !important",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDesData(data);
              }}
            />
          </Form.Item>
          <Form.Item
            name="fund_amount"
            label="Fund Amount"
            rules={[
              {
                required: true,
                message: "Amount cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Amount" />
          </Form.Item>
          <Form.Item
            name="box_color"
            label="Box Color"
            rules={[
              {
                required: true,
                message: "Box color be empty!",
              },
            ]}
          >
            <Input
              type="text"
              size="large"
              placeholder="Enter the color code"
            />
          </Form.Item>
          <Form.Item
            name="box_title"
            label="Box Title"
            rules={[
              {
                required: true,
                message: "Box title be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Enter the box title" />
          </Form.Item>
          <p className="tt_line">
            Contact details of expert who will be handling the fund
            <span style={{ color: "red" }}>*</span>
          </p>
          <Form.Item
            name="expert_name"
            label="Expert Name"
            rules={[
              {
                required: true,
                message: "Name cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="email_address"
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Amount cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Email address" />
          </Form.Item>
          <Form.Item name="img_url" label="Upload profile image">
            <Input
              type="file"
              placeholder="Upload profile image"
              onChange={(e) => uploadFileChange(e)}
              accept="image/*"
            />
          </Form.Item>
          <Form.Item name="expert_description" label="Expert description">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "Content description",
                toolbar: ckeditorConfig.toolbar,
              }}
              editor={ClassicEditor}
              data={expertDesData}
              onChange={(event, editor) => {
                const data = editor.getData();
                setExpertDesData(data);
              }}
            />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditFund;
