import { Button, Form, Input, message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { PostData } from "./../apiService/PostData";
import { apis } from "../properties";
import Auth from "../auth/Auth";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store";
import { ErrorCommon } from "../util/ErrorCommon";

export default function Login() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const setLogUser = useStore((state) => state.setLogUser);
  const setActiveRoute = useStore((state) => state.setActiveRoute);
  const [loading, setLoading] = useState(false);

  const [postData, setPostData] = useState({});
  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const userLogin = () => {
    Progress.show();
    setLoading(true);

    PostData(apis.ADMIN_LOGIN, postData).then((result) => {
      let responseJson = result;
      if (responseJson.errors) {
        ErrorCommon(responseJson.errors);
        setLoading(false);
      } else {
        if (responseJson.status === "success") {
          message.success("Successfully login");
          setLogUser(
            true,
            responseJson.access_token,
            responseJson.user,
          );
          setActiveRoute("dashboard");
          setLoading(false);
          Progress.hide();
          Auth.login(() => {
            navigate("/dashboard");
          });
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
          setLoading(false);
        }
      }
    });
  };

  return (
    <div className="outer__container">
      <div className="outer_box">
        <div className="outer_side_banner"></div>
        <div className="outer_side_content">
          <div className="outer_logo">
            <img src="/images/atml-logo-clr.png" alt="logo" />
          </div>
          <div className="content">
            <div className="login_title">
              <h1>Login</h1>
              <p>Login to the Dashboard</p>
            </div>
            <Form
              autoComplete="off"
              form={form}
              onFinish={userLogin}
              onFinishFailed={onFinishFailed}
              initialValues={{ remember: true }}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Username cannot be empty!" },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Email Address"
                  onChange={(e) =>
                    setPostData({ ...postData, email: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Password cannot be empty!" },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Password"
                  onChange={(e) =>
                    setPostData({ ...postData, password: e.target.value })
                  }
                />
              </Form.Item>
              {/* <p className="fw-link">
                <Link to="/forgot-password">Forget Password</Link>
              </p> */}
              <Button
                type="primary"
                size="large"
                className="primary__btn full_width"
                htmlType="submit"
                loading={loading}
              >
                Login
              </Button>
            </Form>
            {/* <p className="signup-link">
              Don't have an account yet? <Link to="/sign-up">Sign Up</Link>
            </p> */}
          </div>
        </div>
      </div>
      <div className="footer">
        <p> &copy; {moment().year()} ATML. All rights reserved.</p>
      </div>
    </div>
  );
}
