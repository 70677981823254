import { Button, Divider, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis, ckeditorConfig, IMAGE_PATH } from "../../properties";
import Progress from "react-progress-2";
import useLangs from "../../hooks/useLangs";
import { LeftOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import useNewsDetails from "../../hooks/useNewsDetails";

const { Option } = Select;

function EditNews() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [selectedFile, setSelectedFile] = useState();

  useBreadCrumb("Edit news", location.pathname, "", "add");

  const [desData, setDesData] = useState();
  const langs = useLangs();

  const [filterData] = useState({
    lang_id: params.lang_id,
    slug: params.slug,
  });

  const newsDetails = useNewsDetails(filterData);

  useEffect(() => {
    if (newsDetails) {
      form.setFieldsValue({
        title: newsDetails.title,
        slug: newsDetails.slug,
        lang_id: newsDetails.lang_id,
        seo_title: newsDetails.seo_title,
        seo_description: newsDetails.seo_description,
        seo_keywords: newsDetails.seo_keywords,
      });
      setDesData(newsDetails.description);
    }
    // eslint-disable-next-line
  }, [newsDetails]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const data = {
        ...values,
        description: desData,
      };
      const body = new FormData();
      body.append("data", JSON.stringify(data));
      body.append("cover_img", selectedFile);

      PostDataAuth(apis.NEWS_UPDATE, body).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const uploadFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            body.append("location", "cdeditor-images");
            fetch(`${apis.CKEDITOR_UPLOAD}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${IMAGE_PATH}/${res.data.file_path}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Edit news</p>
        </div>

        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <Form.Item
            label="News title"
            name="title"
            rules={[
              {
                required: true,
                message: "Title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Content title" />
          </Form.Item>
          <Form.Item name="slug" label="Slug">
            <Input type="text" size="large" placeholder="slug" disabled />
          </Form.Item>

          <Form.Item
            label="Select language"
            name="lang_id"
            required
            rules={[
              {
                required: true,
                message: "Please select language!",
              },
            ]}
          >
            <Select size="large" placeholder="Select language">
              {langs.map((item, index) => (
                <Option key={index} value={item.lang}>
                  {item.description}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="description" label="Descriptions">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "Content description",
                toolbar: ckeditorConfig.toolbar,
              }}
              editor={ClassicEditor}
              data={desData}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "z-index",
                    "999999 !important",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDesData(data);
              }}
            />
          </Form.Item>
          <Form.Item name="file_path" label="Upload Cover image">
            <Input
              type="file"
              placeholder="Upload pie chart"
              onChange={(e) => uploadFileChange(e)}
              accept="jpg, jpeg, png"
            />
          </Form.Item>
          <Divider orientation="left" plain>
            SEO Information
          </Divider>
          <Form.Item
            label="SEO title"
            name="seo_title"
            rules={[
              {
                required: true,
                message: "SEO title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="SEO title" />
          </Form.Item>
          <Form.Item
            label="SEO description"
            name="seo_description"
            rules={[
              {
                required: true,
                message: "SEO description cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="SEO description" />
          </Form.Item>
          <Form.Item
            label="SEO keywords"
            name="seo_keywords"
            rules={[
              {
                required: true,
                message: "SEO keywords cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="SEO keywords" />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditNews;
