import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReportsDataTable from "../components/Reports/ReportsDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";


export default function FundReports() {
  const navigate = useNavigate();
  const location = useLocation();
  useBreadCrumb("Fund Reports", location.pathname, "Fund Reports");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/fund-reports/create")}
          >
            Add Reports
          </Button>
        </div>
        <ReportsDataTable />
      </div>
    </div>
  );
}
