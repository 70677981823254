export const LIVE_MAIN_API_URL = process.env.REACT_APP_API_URL;
export const IMAGE_PATH = process.env.REACT_APP_IMAGEKIT_URL;

export const BASE_API_URL = `${LIVE_MAIN_API_URL}`;

export const apis = {
  ADMIN_LOGIN: `${BASE_API_URL}api/cms/v1/admin/login`,
  LANG_LIST: `${BASE_API_URL}api/cms/v1/admin/lang`,

  FUND_CREATE: `${BASE_API_URL}api/cms/v1/admin/fund/create`,
  FUND_LIST: `${BASE_API_URL}api/cms/v1/admin/fund/list`,
  FUND_DETAIL: `${BASE_API_URL}api/cms/v1/admin/fund/detail`,
  FUND_UPDATE: `${BASE_API_URL}api/cms/v1/admin/fund/update`,
  FUND_DELETE: `${BASE_API_URL}api/cms/v1/admin/fund/delete`,
  FUND_STATUS_CHANGE: `${BASE_API_URL}api/cms/v1/admin/fund/status-change`,
  FUND_MULTI_LANG_STATUS_CHANGE: `${BASE_API_URL}api/cms/v1/admin/fund/multi-lang-status-change`,
  UNIT_PRICE_MANAGE: `${BASE_API_URL}api/cms/v1/admin/fund/unit-price-management`,
  UNIT_PRICE_MANAGE_LIST: `${BASE_API_URL}api/cms/v1/admin/fund/unit-price-management/list`,
  UNIT_PRICE_MANAGE_DELETE: `${BASE_API_URL}api/cms/v1/admin/fund/unit-price-management/delete`,

  FUND_CONTENTS_LIST: `${BASE_API_URL}api/cms/v1/admin/fund-content/list`,
  FUND_CONTENTS_CREATE: `${BASE_API_URL}api/cms/v1/admin/fund-content/create`,
  FUND_CONTENTS_CREATE_OTHER: `${BASE_API_URL}api/cms/v1/admin/fund-content/other-lang-create`,
  FUND_CONTENTS_DETAILS: `${BASE_API_URL}api/cms/v1/admin/fund-content/detail`,
  FUND_CONTENTS_DELETE: `${BASE_API_URL}api/cms/v1/admin/fund-content/delete`,
  FUND_CONTENTS_UPDATE: `${BASE_API_URL}api/cms/v1/admin/fund-content/update`,
  FUND_CONTENTS_STATUS_CHANGE: `${BASE_API_URL}api/cms/v1/admin/fund-content/status-change`,

  NEWS_LIST: `${BASE_API_URL}api/cms/v1/admin/news/list`,
  NEWS_CREATE: `${BASE_API_URL}api/cms/v1/admin/news/create`,
  NEWS_DETAIL: `${BASE_API_URL}api/cms/v1/admin/news/detail`,
  NEWS_UPDATE: `${BASE_API_URL}api/cms/v1/admin/news/update`,
  NEWS_DELETE: `${BASE_API_URL}api/cms/v1/admin/news/delete`,
  NEWS_STATUS_CHANGE: `${BASE_API_URL}api/cms/v1/admin/news/status-change`,
  NEWS_MULTI_LANG_STATUS_CHANGE: `${BASE_API_URL}api/cms/v1/admin/news/multi-lang-status-change`,

  CKEDITOR_UPLOAD: `${BASE_API_URL}api/ckeditor-upload`,
  CHART_FILE_UPLOAD: `${BASE_API_URL}api/cms/v1/admin/chart-file-upload`,

  REPORT_CATEGORY_CREATE: `${BASE_API_URL}api/cms/v1/admin/fund-reports/category/create`,
  REPORTS_CATEGORY_LIST: `${BASE_API_URL}api/cms/v1/admin/fund-reports/category/list`,

  REPORTS_CREATE: `${BASE_API_URL}api/cms/v1/admin/fund-reports/create`,
  REPORTS_LIST: `${BASE_API_URL}api/cms/v1/admin/fund-reports/list`,
  REPORTS_DELETE: `${BASE_API_URL}api/cms/v1/admin/fund-reports/delete`,
  REPORTS_DETAILS: `${BASE_API_URL}api/cms/v1/admin/fund-reports/detail`,
  REPORTS_UPDATE: `${BASE_API_URL}api/cms/v1/admin/fund-reports/update`,
  REPORTS_STATUS_CHANGE: `${BASE_API_URL}api/cms/v1/admin/fund-reports/status-change`,

  DOWNLOAD_CATEGORY_CREATE: `${BASE_API_URL}api/cms/v1/admin/fund-download/category/create`,
  DOWNLOAD_CATEGORY_LIST: `${BASE_API_URL}api/cms/v1/admin/fund-download/category/list`,

  DOWNLOAD_SUB_CATEGORY_CREATE: `${BASE_API_URL}api/cms/v1/admin/fund-download/sub-category/create`,
  DOWNLOAD_SUB_CATEGORY_LIST: `${BASE_API_URL}api/cms/v1/admin/fund-download/sub-category/list`,

  DOWNLOAD_CREATE: `${BASE_API_URL}api/cms/v1/admin/fund-download/create`,
  DOWNLOAD_LIST: `${BASE_API_URL}api/cms/v1/admin/fund-download/list`,
  DOWNLOAD_DELETE: `${BASE_API_URL}api/cms/v1/admin/fund-download/delete`,
  DOWNLOAD_DETAILS: `${BASE_API_URL}api/cms/v1/admin/fund-download/details`,
  DOWNLOAD_UPDATE: `${BASE_API_URL}api/cms/v1/admin/fund-download/update`,
  DOWNLOAD_STATUS_CHANGE: `${BASE_API_URL}api/cms/v1/admin/fund-download/status-change`,

  FAQS_CATEGORY_CREATE: `${BASE_API_URL}api/cms/v1/admin/fund-faqs/category/create`,
  FAQS_CATEGORY_LIST: `${BASE_API_URL}api/cms/v1/admin/fund-faqs/category/list`,

  FAQS_CREATE: `${BASE_API_URL}api/cms/v1/admin/fund-faqs/create`,
  FAQS_LIST: `${BASE_API_URL}api/cms/v1/admin/fund-faqs/list`,
  FAQS_DETAILS: `${BASE_API_URL}api/cms/v1/admin/fund-faqs/details`,
  FAQS_UPDATE: `${BASE_API_URL}api/cms/v1/admin/fund-faqs/update`,
  FAQS_DELETE: `${BASE_API_URL}api/cms/v1/admin/fund-faqs/delete`,
  FAQS_STATUS_CHANGE: `${BASE_API_URL}api/cms/v1/admin/fund-faqs/status-change`,
};

export const ckeditorConfig = {
  toolbar: [
    "heading",
    "|",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "|",
    "insertTable",
    "mediaEmbed",
    "|",
    "undo",
    "redo",
    "|",
    "imageUpload",
    "imageStyle:full",
    "imageStyle:side",
    "|",
    "code",
    "CodeBlock",
    "|",
  ],
};
