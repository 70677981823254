import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DownloadsDataTable from "../components/Downloads/DownloadsDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";


export default function FundDownloads() {
  const navigate = useNavigate();
  const location = useLocation();
  useBreadCrumb("Fund Downloads", location.pathname, "Fund Downloads");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/fund-downloads/create")}
          >
            Add Downloads
          </Button>
        </div>
        <DownloadsDataTable />
      </div>
    </div>
  );
}
