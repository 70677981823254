export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/dashboard",
    iconClassName: "bi bi-cast",
    route_key: "dashboard",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Fund Management",
    exact: true,
    to: "/fund-management",
    iconClassName: "bi bi-wallet2",
    route_key: "fund-management",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Fund Contents",
    exact: true,
    to: "/fund-contents",
    iconClassName: "bi bi-collection",
    route_key: "fund-contents",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Funds Reports",
    exact: true,
    to: "/fund-reports",
    iconClassName: "bi bi-archive",
    route_key: "fund-reports",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Funds Downloads",
    exact: true,
    to: "/fund-downloads",
    iconClassName: "bi bi-file-earmark-arrow-down",
    route_key: "fund-downloads",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Funds FAQs",
    exact: true,
    to: "/fund-faqs",
    iconClassName: "bi bi-question-square",
    route_key: "fund-faqs",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "News",
    exact: true,
    to: "/news",
    iconClassName: "bi bi-megaphone",
    route_key: "news",
    privilege: ["SUPER_ADMIN"],
  },
];
