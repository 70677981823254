import { FilterOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, Select } from "antd";
import React from "react";
// import useReportCateList from "../../hooks/useReportCateList";
import useAllFunds from "../../hooks/useAllFunds";
const { Option } = Select;
const { Panel } = Collapse;

export const FilterRow = ({ filterData, setFilterData }) => {
  const [form] = Form.useForm();
  // const reportCateList = useReportCateList();
  const allFunds = useAllFunds();

  const onFinish = (values) => {
    setFilterData({
      ...filterData,
      title: values.title,
      fund_slug: values.fund_slug,
      order: values.order,
    });
  };

  const clearData = () => {
    setFilterData({
      ...filterData,
      title: "",
      fund_slug: "",
      order: "",
    });
    form.resetFields();
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Filter">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="title" noStyle>
              <Input type="text" allowClear placeholder="Title Name" />
            </Form.Item>
            <Form.Item name="fund_slug" noStyle>
              <Select
                placeholder="Select Fund"
                allowClear
                className="full_width"
              >
                {allFunds.map((item, index) => (
                  <Option key={index} value={item.slug}>
                  {item.title}
                </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="order" noStyle>
              <Select
                placeholder="Select Order"
                allowClear
                className="full_width"
              >
                <Option value="asc">Ascending (1-9)</Option>
                <Option value="desc">Descending (9-1)</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
