import { Button, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis, ckeditorConfig, IMAGE_PATH } from "../../properties";
import Progress from "react-progress-2";
import useLangs from "../../hooks/useLangs";
import useFundContentDetails from "../../hooks/useFundContentDetails";
import { LeftOutlined } from "@ant-design/icons";
import useAllFunds from "../../hooks/useAllFunds";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import useReportCateList from "../../hooks/useReportCateList";
import useDownloadCateList from "../../hooks/useDownloadCateList";
import useFaqsCateList from "../../hooks/useFaqsCateList";

const { Option } = Select;

function EditFundContent() {
  const params = useParams();
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [desData, setDesData] = useState();
  const reportCateList = useReportCateList();
  const downloadCateList = useDownloadCateList();
  const [cardResData, setCardResData] = useState();
  const [chartData, setChartData] = useState({
    file: null,
    type: null,
  });

  useBreadCrumb("Create content", location.pathname, "", "add");

  const [filterData] = useState({
    status: "all",
    lang_id: "en",
    id: params.id,
  });

  const allFunds = useAllFunds(filterData);
  const langs = useLangs();
  const faqsCateList = useFaqsCateList();

  const fundContentDetails = useFundContentDetails(filterData);

  useEffect(() => {
    if (fundContentDetails) {
      form.setFieldsValue({
        title: fundContentDetails.title,
        slug: fundContentDetails.fund_slug,
        lang_id: fundContentDetails.lang_id,
        description: fundContentDetails.description,
        download_id: fundContentDetails.download_id,
        report_id: fundContentDetails.report_id,
        order_content: fundContentDetails.order_content,
        fund_slug: fundContentDetails.fund_slug,
        faqs_id: fundContentDetails.faqs_id,
      });
      setDesData(fundContentDetails?.description ? fundContentDetails?.description : "");
    }
    // eslint-disable-next-line
  }, [fundContentDetails]);
  
  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    console.log(cardResData);
    form.validateFields().then((values) => {
      Progress.show();
      const data = {
        ...values,
        description: desData,
        chart_id: cardResData,
        id: params.id,
      };
      PostDataAuth(apis.FUND_CONTENTS_UPDATE, data).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            body.append("location", "cdeditor-images");
            fetch(`${apis.CKEDITOR_UPLOAD}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${IMAGE_PATH}/${res.data.file_path}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const uploadFileChange = (event, type) => {
    setChartData({
      file: event.target.files[0],
      type: type,
    });
  };

  const handleChartData = () => {
    if (chartData.file) {
      var formData = new FormData();
      formData.append("file", chartData.file);
      formData.append("type", chartData.type);
      formData.append("status", "New");
      Progress.show();
      axios
        .post(apis.CHART_FILE_UPLOAD, formData, {
          headers: {
            token: "dad#lecture#Charger#lot7",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            Progress.hide();
            setCardResData(response.data.data.id);
            message.success(response.data.message);
          } else {
            Progress.hide();
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          Progress.hide();
          console.log(error);
          message.error("Something went wrong");
        });
    } else {
      Progress.hide();
      message.error("Please select a file");
    }
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Create new content</p>
        </div>

        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: "Title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Content title" />
          </Form.Item>
          <Form.Item
            name="order_content"
            rules={[
              {
                required: true,
                message: "Order cannot be empty!",
              },
            ]}
          >
            <Input type="number" size="large" placeholder="Order number" />
          </Form.Item>
          <Form.Item
            name="fund_slug"
            required
            rules={[
              {
                required: true,
                message: "Please select a Fund!",
              },
            ]}
          >
            <Select size="large" placeholder="Select Main Fund">
              {allFunds.map((item, index) => (
                <Option key={index} value={item.slug}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="lang_id"
            required
            rules={[
              {
                required: true,
                message: "Please select language!",
              },
            ]}
          >
            <Select size="large" placeholder="Select language">
              {langs.map((item, index) => (
                <Option key={index} value={item.lang}>
                  {item.description}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="description">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "Content description",
                toolbar: ckeditorConfig.toolbar,
              }}
              editor={ClassicEditor}
              data={desData}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "z-index",
                    "999999 !important",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDesData(data);
              }}
            />
          </Form.Item>
          <div className="rg_box">
            <div className="com_head">
              <p className="common_header">
                Upload pie chart data |
                <span>
                  {" "}
                  download the sample file before upload{" "}
                  <a
                    href={`${IMAGE_PATH}/sample/pie-chart-format.csv`}
                    target="_blank"
                    rel="noreferrer"
                    className="alLink"
                  >
                    (Download sample file)
                  </a>
                </span>
              </p>
            </div>
            <div className="rg_box_row">
              <Form.Item name="uploaded_pie_chart" noStyle>
                <Input
                  type="file"
                  placeholder="Upload pie chart"
                  onChange={(e) => uploadFileChange(e, "pieChart")}
                  accept="csv"
                />
              </Form.Item>
              <Button
                type="button"
                className="default__btn"
                onClick={() => handleChartData()}
              >
                Upload file
              </Button>
            </div>
          </div>
          <div className="rg_box">
            <div className="com_head">
              <p className="common_header">
                Upload Line chart data |
                <span>
                  {" "}
                  download the sample file before upload{" "}
                  <a
                    href={`${IMAGE_PATH}/sample/line-chart-format.csv`}
                    target="_blank"
                    rel="noreferrer"
                    className="alLink"
                  >
                    (Download sample file)
                  </a>
                </span>
              </p>
            </div>
            <div className="rg_box_row">
              <Form.Item name="uploaded_line_chart" noStyle>
                <Input
                  type="file"
                  placeholder="Upload pie chart"
                  onChange={(e) => uploadFileChange(e, "lineChart")}
                  accept="csv"
                />
              </Form.Item>
              <Button
                type="button"
                className="default__btn"
                onClick={() => handleChartData()}
              >
                Upload file
              </Button>
            </div>
          </div>

          <div className="rg_box">
            <div className="com_head">
              <p className="common_header">
                Reports Section | <span>Select the reports category</span>
              </p>
            </div>
            <Form.Item name="report_id" required>
              <Select size="large" placeholder="Select report category">
                {reportCateList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="rg_box">
            <div className="com_head">
              <p className="common_header">
                Download Section | <span>Select the download category</span>
              </p>
            </div>
            <Form.Item name="download_id">
              <Select size="large" placeholder="Select Download Category">
                {downloadCateList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="rg_box">
            <div className="com_head">
              <p className="common_header">
                Faqs Section | <span>Select the Faqs category</span>
              </p>
            </div>
            <Form.Item name="faqs_id">
              <Select size="large" placeholder="Select Faqs Category">
                {faqsCateList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditFundContent;
