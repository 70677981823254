import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal, Table, Tag, Tooltip, message } from "antd";
import { PostDataAuth } from "../../apiService/PostData";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import moment from "moment";
import useUnitPriceManagement from "../../hooks/useUnitPriceManagement";
import { useRefreshTable } from "../../store";

const { confirm } = Modal;

function PriceManagement() {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useBreadCrumb("Unit price management", location.pathname, "", "add");
  const { refreshTable, setRefreshTable } = useRefreshTable();

  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    dataTable: true,
    fund_slug: params.slug,
  });

  const unitPriceManagement = useUnitPriceManagement(filterData);

  useEffect(() => {
    form.setFieldsValue({
      created_date: moment().format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line
  }, []);

  const submitForm = () => {
    form.validateFields().then((values) => {
      PostDataAuth(apis.UNIT_PRICE_MANAGE, {
        ...values,
        fund_slug: params.slug,
      }).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          form.setFieldsValue({
            created_date: moment().format("YYYY-MM-DD"),
          });
          setRefreshTable(!refreshTable);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const onEditForm = (record) => {
    form.setFieldsValue({
      ...record,
      created_date: moment(record.created_date).format("YYYY-MM-DD"),
    });
  };

  const deleteFundContent = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this content?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        PostDataAuth(apis.UNIT_PRICE_MANAGE_DELETE, {
          id: id,
        }).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Created Date",
      render: (record) =>
        record.created_date
          ? moment(record.created_date).format("YYYY-MM-DD")
          : "",
      width: 150,
    },
    {
      title: "Buying Price",
      dataIndex: "buying_price",
      width: 150,
    },
    {
      title: "Selling Price",
      dataIndex: "selling_price",
      width: 150,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={record.status === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
      fixed: "right",
      width: 50,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Edit Fund">
            <Button
              className="view_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => onEditForm(record)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Delete News">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteFundContent(record.id)}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 60,
      align: "right",
    },
  ];

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Unit price management</p>
        </div>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <div className="form_row">
            <Form.Item
              name="created_date"
              label="Unit Date"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Unit Date cannot be empty!",
                },
              ]}
            >
              <Input type="text" size="large" placeholder="Unit Date" />
            </Form.Item>
            <Form.Item
              name="buying_price"
              label="Buying Price"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Buying Price cannot be empty!",
                },
                {
                  pattern: new RegExp(/^[0-9]*\.?[0-9]*$/),
                  message: "Buying Price must be a number!",
                },
              ]}
            >
              <Input type="text" size="large" placeholder="Buying Price" />
            </Form.Item>
            <Form.Item
              name="selling_price"
              label="Selling Price"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Selling Price cannot be empty!",
                },
                {
                  pattern: new RegExp(/^[0-9]*\.?[0-9]*$/),
                  message: "Selling Price must be a number!",
                },
              ]}
            >
              <Input type="text" size="large" placeholder="Selling Price" />
            </Form.Item>
            <Button type="primary" className="primary__btn" htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
        <Table
          columns={columns}
          dataSource={unitPriceManagement.data}
          bordered
          pagination={{
            current: filterData.current_page,
            pageSize: filterData.pageSize,
            total: unitPriceManagement.total,
            onChange: (page, pageSize) => {
              setFilterData({
                ...filterData,
                page: page,
                pageSize: pageSize,
              });
            },
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
        />
      </div>
    </div>
  );
}

export default PriceManagement;
