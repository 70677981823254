import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import moment from "moment";
import Progress from "react-progress-2";
import { GetDataAuth } from "../../apiService/GetData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import { useNavigate, useParams } from "react-router-dom";
import useFundDetails from "../../hooks/useFundDetails";
import parse from "html-react-parser";

const { confirm } = Modal;

function MultiLangsTable() {
  const navigate = useNavigate();
  const params = useParams();

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    dataTable: true,
    slug: params.slug,
  });

  const fundDetails = useFundDetails(filterData);

  const columns = [
    {
      title: "Fund Name",
      dataIndex: "title",
      width: 150,
    },
    {
      title: "Fund Slug",
      dataIndex: "slug",
      width: 150,
    },
    {
      title: "Fund Description",
      render: (record) => parse(record.description.substring(0, 120) + "..."),
      width: 200,
    },
    {
      title: "Fund Amount",
      dataIndex: "fund_amount",
      width: 100,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 150,
    },
    {
      title: "Language Code",
      dataIndex: "lang_id",
      width: 80,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={record.status === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
      fixed: "right",
      width: 50,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Edit Fund">
            <Button
              className="view_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() =>
                navigate(
                  `/fund-management/edit/${record.lang_id}/${record.slug}`
                )
              }
            />
          </Tooltip>
          &nbsp;&nbsp;
          {record.status === 1 ? (
            <Tooltip placement="bottom" title="Deactivate Fund">
              <Button
                className="delete_button"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => statusChange(record.id, "Inactive")}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="Activate Fund">
              <Button
                className="view_button"
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => statusChange(record.id, "Active")}
              />
            </Tooltip>
          )}
        </>
      ),
      fixed: "right",
      width: 80,
      align: "right",
    },
  ];

  const statusChange = (id, type) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${type} this fund language type?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.FUND_MULTI_LANG_STATUS_CHANGE}/${id}`).then(
          (result) => {
            let responseJson = result;
            if (responseJson.status === "success") {
              message.success(responseJson.message);
              setRefreshTable(!refreshTable);
              Progress.hide();
            } else if (responseJson.status === "error") {
              message.error(responseJson.message);
              Progress.hide();
            }
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Table
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={fundDetails.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: fundDetails.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default MultiLangsTable;
