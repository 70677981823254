import { Button, DatePicker, Form, Input, message, Select } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import useReportCateList from "../../hooks/useReportCateList";
import { months } from "../../mockData/months";
import { reportTabs } from "../../mockData/reportTabs";
import AddReportCateModel from "./AddReportCateModel";

const { Option } = Select;

function AddReport() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [tabName, setTabName] = useState();
  const [visible, setVisible] = useState(false);

  useBreadCrumb("Create report", location.pathname, "", "add");

  const reportCateList = useReportCateList();

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const body = new FormData();
      body.append("report_name", values.report_name);
      body.append("report_cate_id", values.report_cate_id);
      body.append("report_file", selectedFile);
      body.append("tab_name", values.tab_name);
      body.append("publish_month", values.publish_month ?? "");
      body.append("publish_year", values.publish_year);

      PostDataAuth(apis.REPORTS_CREATE, body).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const uploadFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Report Upload</p>
        </div>

        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <Form.Item
            label="Report Name (Enter the name of the report)"
            name="report_name"
            rules={[
              {
                required: true,
                message: "Report Name cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Report Name" />
          </Form.Item>

          <div className="same_row">
            <Form.Item
              name="report_cate_id"
              label="Fund Name (Select a fund)"
              className="full_width"
              rules={[
                {
                  required: true,
                  message: "Fund name cannot be empty!",
                },
              ]}
            >
              <Select size="large" placeholder="Select Report Category">
                {reportCateList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div className="mt-20">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setVisible(true)}
                size="large"
              />
            </div>
          </div>

          <Form.Item
            name="tab_name"
            label="Report Type (Select a report type)"
            rules={[
              {
                required: true,
                message: "Report type cannot be empty!",
              },
            ]}
          >
            <Select
              size="large"
              placeholder="Select Report Type"
              onChange={(value) => {
                setTabName(value);
              }}
            >
              {reportTabs.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {(tabName === "fact_sheet" || tabName === "interim_report") && (
            <Form.Item
              label="Publish Month (Select the month of the report)"
              name="publish_month"
              rules={[
                {
                  required: true,
                  message: "Publish Month cannot be empty!",
                },
              ]}
            >
              <Select placeholder="Select Month" size="large">
                {months.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label="Publish Year (Select the year of the report)"
            name="publish_year"
            rules={[
              {
                required: true,
                message: "Publish Year cannot be empty!",
              },
            ]}
          >
            <DatePicker
              size="large"
              placeholder="Publish Year"
              className="full_width"
              picker="year"
            />
          </Form.Item>

          <p className="txt_p">Upload Limit: 10 MB (JPG, PDF formats only)</p>
          <Form.Item name="file_path">
            <Input
              size="large"
              type="file"
              placeholder="Upload pie chart"
              onChange={(e) => uploadFileChange(e)}
              accept="csv"
            />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
      {visible && (
        <AddReportCateModel
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
        />
      )}
    </div>
  );
}

export default AddReport;
