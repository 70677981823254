import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import MultiLangsNewsTable from "./MultiLangsNewsTable";


export default function MultiNewsLangs() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useBreadCrumb("Multi Language", location.pathname, "", "add");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/news/multi-language/create/" + params.slug)}
          >
            Add New Language Type
          </Button>
        </div>
        <MultiLangsNewsTable />
      </div>
    </div>
  );
}
