import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NewsDataTable from "../components/News/NewsDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";


export default function News() {
  const navigate = useNavigate();
  const location = useLocation();
  useBreadCrumb("News", location.pathname, "News");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/news/create")}
          >
            Add New
          </Button>
        </div>
        <NewsDataTable />
      </div>
    </div>
  );
}
