import "react-progress-2/main.css";
import "./assets/css/Dashboard.css";
import "./assets/css/Login.css";
import "./assets/css/Header.css";
import "./assets/css/MenuSideBar.css";
import "./assets/css/ThemeChange.css";
import "./assets/css/Responsive.css";

import Progress from "react-progress-2";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import NotFount from "./errorPage/404";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Layout from "./Layout";
import Login from "./pages/Login";
import FundManagement from "./pages/FundManagement";
import News from "./pages/News";
import Settings from "./pages/Settings";
import AddFund from "./components/Fund/AddFund";
import EditFund from "./components/Fund/EditFund";
import MultiLangs from "./components/Fund/MultiLangs";
import FundContents from "./pages/FundContents";
import AddFundContent from "./components/FundContents/AddFundContent";
import AddNews from "./components/News/AddNews";
import EditNews from "./components/News/EditNews";
import MultiNewsLangs from "./components/News/MultiNewsLangs";
import FundReports from "./pages/FundReports";
import FundDownloads from "./pages/FundDownloads";
import AddReport from "./components/Reports/AddReport";
import EditReport from "./components/Reports/EditReport";
import AddDownload from "./components/Downloads/AddDownload";
import EditFundContent from "./components/FundContents/EditFundContent";
import MultiLangsFundContents from "./components/FundContents/MultiLangsFundContents";
import EditDownload from "./components/Downloads/EditDownload";
import Faqs from "./pages/Faqs";
import AddFaqs from "./components/Faqs/AddFaqs";
import EditFaqs from "./components/Faqs/EditFaqs";
import PriceManagement from "./components/Fund/PriceManagement";

function App() {
  return (
    <div className="App">
      <Progress.Component />
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/fund-management" element={<FundManagement />} />
          <Route path="/fund-management/create" element={<AddFund type={"NEW"}/>} />
          <Route path="/fund-management/edit/:lang_id/:slug" element={<EditFund />} />
          <Route path="/fund-management/multi-language/:slug" element={<MultiLangs />} />
          <Route path="/fund-management/multi-language/create/:slug" element={<AddFund  type={"FIXED_SLUG"}/>} />
          <Route path="/fund-management/unit-price-management/:slug" element={<PriceManagement />} />

          <Route path="/fund-contents" element={<FundContents />} />
          <Route path="/fund-contents/create" element={<AddFundContent  type={"NEW"}/>} />
          <Route path="/fund-contents/edit/:id" element={<EditFundContent />} />
          <Route path="/fund-contents/multi-language/:id/:fund_id" element={<MultiLangsFundContents />} />
          <Route path="/fund-contents/multi-language/create/:id" element={<AddFundContent  type={"FIXED_SLUG"}/>} />

          <Route path="/fund-reports" element={<FundReports />} />
          <Route path="/fund-reports/create" element={<AddReport />} />
          <Route path="/fund-reports/edit/:id" element={<EditReport />} />

          <Route path="/fund-downloads" element={<FundDownloads />} />
          <Route path="/fund-downloads/create" element={<AddDownload />} />
          <Route path="/fund-downloads/edit/:id" element={<EditDownload />} />

          <Route path="/news" element={<News />} />
          <Route path="/news/create" element={<AddNews type={"NEW"}/>} />
          <Route path="/news/edit/:lang_id/:slug" element={<EditNews />} />
          <Route path="/news/multi-language/:slug" element={<MultiNewsLangs />} />
          <Route path="/news/multi-language/create/:slug" element={<AddNews type={"FIXED_SLUG"}/>} />

          <Route path="/fund-faqs" element={<Faqs />} />
          <Route path="/fund-faqs/create" element={<AddFaqs />} />
          <Route path="/fund-faqs/edit/:id" element={<EditFaqs />} />


          <Route path="/settings" element={<Settings />} />
        </Route>

        <Route path="*" element={<NotFount />} />
      </Routes>
    </div>
  );
}

export default App;
