export const reportTabs = [
  {
    id: "fact_sheet",
    name: "Fact Sheet",
  },
  {
    id: "interim_report",
    name: "Interim Report",
  },
  {
    id: "annual_report",
    name: "Annual Report",
  },
];
