import { FilterOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, Select } from "antd";
import React from "react";
import useReportCateList from "../../hooks/useReportCateList";
import { reportTabs } from "../../mockData/reportTabs";
const { Option } = Select;
const { Panel } = Collapse;

export const FilterRow = ({ filterData, setFilterData }) => {
  const [form] = Form.useForm();
  const reportCateList = useReportCateList();

  const onFinish = (values) => {
    setFilterData({
      ...filterData,
      report_cate_id: values.report_cate_id,
      tab_name: values.tab_name,
      report_name: values.report_name,
    });
  };

  const clearData = () => {
    setFilterData({
      ...filterData,
      report_cate_id: "",
      tab_name: "",
      report_name: "",
    });
    form.resetFields();
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Filter">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="report_name" noStyle>
              <Input type="text" allowClear placeholder="Report Name" />
            </Form.Item>
            <Form.Item name="report_cate_id" noStyle>
              <Select
                placeholder="Select Report Category"
                allowClear
                className="full_width"
              >
                {reportCateList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="tab_name" noStyle>
              <Select
                placeholder="Select Report Category"
                allowClear
                className="full_width"
              >
                {reportTabs.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
