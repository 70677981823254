import { Button, Form, Input, message, Select } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import useDownloadCateList from "../../hooks/useDownloadCateList";
import AddDownloadCateModel from "./AddDownloadCateModel";
import useDownloadSubCateList from "../../hooks/useDownloadSubCateList";
import AddDownloadSubCateModel from "./AddDownloadSubCateModel";

const { Option } = Select;

function AddDownload() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleSub, setVisibleSub] = useState(false);
  const [selectCate, setSelectCate] = useState();
  const [selectSubCate, setSelectSubCate] = useState();

  useBreadCrumb("Create download", location.pathname, "", "add");

  const downloadCateList = useDownloadCateList();
  const downloadSubCateList = useDownloadSubCateList();

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const body = new FormData();
      body.append("name", values.name);
      body.append("fund_download_cate_id", selectCate);
      body.append("fund_download_sub_cate_id", selectSubCate);
      body.append("file_path", selectedFile);

      PostDataAuth(apis.DOWNLOAD_CREATE, body).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const uploadFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Create download</p>
        </div>

        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Name cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="File Name" />
          </Form.Item>

          <Form.Item name="fund_download_cate_id">
            <div className="same_row">
              <Select
                size="large"
                placeholder="Select Download Category"
                onChange={(value) => {
                  setSelectCate(value);
                }}
              >
                {downloadCateList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setVisible(true)}
                size="large"
              />
            </div>
          </Form.Item>

          <Form.Item name="fund_download_sub_cate_id">
            <div className="same_row">
              <Select
                size="large"
                placeholder="Select Download Title Category"
                onChange={(value) => {
                  setSelectSubCate(value);
                }}
              >
                {downloadSubCateList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setVisibleSub(true)}
                size="large"
              />
            </div>
          </Form.Item>

          <Form.Item name="file_path">
            <Input
              type="file"
              placeholder="Upload pie chart"
              onChange={(e) => uploadFileChange(e)}
              accept="csv"
            />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
      {visible && (
        <AddDownloadCateModel
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
        />
      )}
       {visibleSub && (
        <AddDownloadSubCateModel
          visible={visibleSub}
          setVisible={() => {
            setVisibleSub(!visibleSub);
          }}
        />
      )}
    </div>
  );
}

export default AddDownload;
