import { Button, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis, ckeditorConfig, IMAGE_PATH } from "../../properties";
import Progress from "react-progress-2";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddFaqsCateModel from "./AddFaqsCateModel";
import useFaqsCateList from "../../hooks/useFaqsCateList";
import useFundFaqsDetails from "../../hooks/useFundFaqsDetails";

const { Option } = Select;

function EditFaqs() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [desData, setDesData] = useState();
  const params = useParams();

  useBreadCrumb("Create faqs", location.pathname, "", "add");

  const faqsCateList = useFaqsCateList();

  const [filterData] = useState({
    id: params.id,
  });
  const fundFaqsDetails = useFundFaqsDetails(filterData);

  useEffect(() => {
    if (fundFaqsDetails) {
      form.setFieldsValue({
        faq_order: fundFaqsDetails.faq_order,
        faq_title: fundFaqsDetails.faq_title,
        faqs_cate_id: fundFaqsDetails.faqs_cate_id,
      });
      setDesData(fundFaqsDetails.description);
    }
    // eslint-disable-next-line
  }, [fundFaqsDetails]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const data = {
        ...values,
        description: desData,
        id: params.id,
      };
      PostDataAuth(apis.FAQS_UPDATE, data).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            body.append("location", "cdeditor-images");
            fetch(`${apis.CKEDITOR_UPLOAD}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${IMAGE_PATH}/${res.data.file_path}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Create faqs</p>
        </div>

        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="faq_title"
            rules={[
              {
                required: true,
                message: "Faq title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Faq title" />
          </Form.Item>

          <div className="same_row">
            <Form.Item name="faqs_cate_id" className="full_width">
              <Select size="large" placeholder="Select faqs Category">
                {faqsCateList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setVisible(true)}
              size="large"
            />
          </div>
          <Form.Item name="description">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "description",
                toolbar: ckeditorConfig.toolbar,
              }}
              editor={ClassicEditor}
              data={desData}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "z-index",
                    "999999 !important",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDesData(data);
              }}
            />
          </Form.Item>
          <Form.Item
            name="faq_order"
            rules={[
              {
                required: true,
                message: "Order cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Faq Order Number" />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
      {visible && (
        <AddFaqsCateModel
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
        />
      )}
    </div>
  );
}

export default EditFaqs;
