import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { GetDataAuth } from "../../apiService/GetData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import { useNavigate } from "react-router-dom";
import useAllReports from "../../hooks/useAllReports";
import { months } from "../../mockData/months";
import { reportTabs } from "../../mockData/reportTabs";
import { FilterRow } from "./FilterRow";
import moment from "moment";

const { confirm } = Modal;

function ReportsDataTable() {
  const navigate = useNavigate();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    dataTable: true,
    report_cate_id: "",
    tab_name: "",
    report_name: "",
  });

  const allReports = useAllReports(filterData);

  const columns = [
    {
      title: "Report Name",
      dataIndex: "report_name",
      width: 150,
    },
    {
      title: "Fund Name",
      dataIndex: "category_name",
      width: 150,
    },
    {
      title: "Report Type",
      render: (recode) => (
        <span>
          {recode.tab_name !== null
            ? reportTabs.find((item) => item.id === recode.tab_name).name
            : "-"}
        </span>
      ),
      width: 100,
    },
    {
      title: "Publish Year",
      render: (record) =>
        record.publish_year ? moment(record.publish_year).format("YYYY") : "",
      width: 100,
    },
    {
      title: "Publish Month",
      render: (recode) => (
        <span>
          {recode.publish_month !== null
            ? months.find((item) => item.id === recode.publish_month).name
            : "-"}
        </span>
      ),
      width: 100,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={record.status === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
      fixed: "right",
      width: 40,
    },
    {
      title: "Actions",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Edit Fund">
            <Button
              className="view_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => navigate(`/fund-reports/edit/${record.id}`)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          {record.status === 1 ? (
            <Tooltip placement="bottom" title="Deactivate Fund">
              <Button
                className="delete_button"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => statusChange(record.id, "Inactive")}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="Activate Fund">
              <Button
                className="view_button"
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => statusChange(record.id, "Active")}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Delete News">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteNewsContent(record.id)}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 100,
      align: "right",
    },
  ];

  const statusChange = (id, type) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${type} this fund?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.REPORTS_STATUS_CHANGE}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteNewsContent = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this content?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.REPORTS_DELETE}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <FilterRow filterData={filterData} setFilterData={setFilterData} />
      <Table
        columns={columns}
        dataSource={allReports.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allReports.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default ReportsDataTable;
