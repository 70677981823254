import { Button, Form, Input, message, Modal } from "antd";
import React from "react";
import { PostDataAuth } from "../../apiService/PostData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";

function AddDownloadCateModel({ visible, setVisible }) {
  const [form] = Form.useForm();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      PostDataAuth(apis.DOWNLOAD_CATEGORY_CREATE, values).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          message.success(responseJson.message);
          form.resetFields();
          setVisible(false);
          setRefreshTable(!refreshTable);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
        }
      });
    });
  };

  return (
    <Modal
      title="Add Download Category"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
    >
      <div className="step_model_body">
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Name cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Name" />
          </Form.Item>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default AddDownloadCateModel;
