import { Button, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis, IMAGE_PATH } from "../../properties";
import Progress from "react-progress-2";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import useDownloadCateList from "../../hooks/useDownloadCateList";
import AddDownloadCateModel from "./AddDownloadCateModel";
import useDownloadSubCateList from "../../hooks/useDownloadSubCateList";
import AddDownloadSubCateModel from "./AddDownloadSubCateModel";
import useFundDownloadDetails from "../../hooks/useFundDownloadDetails";

const { Option } = Select;

function EditDownload() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleSub, setVisibleSub] = useState(false);
  const params = useParams();

  useBreadCrumb("Edit download", location.pathname, "", "add");

  const downloadCateList = useDownloadCateList();
  const downloadSubCateList = useDownloadSubCateList();

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const [filterData] = useState({
    id: params.id,
  });

  const fundDownloadDetails = useFundDownloadDetails({ filterData });

  useEffect(() => {
    if (fundDownloadDetails) {
      form.setFieldsValue({
        name: fundDownloadDetails.name,
        fund_download_cate_id: fundDownloadDetails.fund_download_cate_id,
        fund_download_sub_cate_id: fundDownloadDetails.fund_download_sub_cate_id,
      });
    }
    // eslint-disable-next-line
  }, [fundDownloadDetails]);


  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const body = new FormData();
      body.append("name", values.name);
      body.append("fund_download_cate_id", values.fund_download_cate_id);
      body.append(
        "fund_download_sub_cate_id",
        values.fund_download_sub_cate_id
      );
      body.append("file_path", selectedFile);
      body.append("id", params.id);

      PostDataAuth(apis.DOWNLOAD_UPDATE, body).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const uploadFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Edit download</p>
        </div>

        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Name cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="File Name" />
          </Form.Item>

          <div className="same_row">
            <Form.Item name="fund_download_cate_id" className="full_width">
              <Select size="large" placeholder="Select Download Category">
                {downloadCateList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setVisible(true)}
              size="large"
            />
          </div>

          <div className="same_row">
            <Form.Item name="fund_download_sub_cate_id" className="full_width">
              <Select size="large" placeholder="Select Download Title Category">
                {downloadSubCateList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setVisibleSub(true)}
              size="large"
            />
          </div>
          <p className="txt_p">
            Uploaded file:{" "}
            <a
              href={IMAGE_PATH + "/" + fundDownloadDetails.file_path}
              target="_blank"
              rel="noreferrer"
              className="alLink"
            >
              here
            </a>
            . If you want to change the file, please upload the new file.
          </p>
          <Form.Item name="file_path">
            <Input
              type="file"
              placeholder="Upload pie chart"
              onChange={(e) => uploadFileChange(e)}
              accept="csv"
            />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
      {visible && (
        <AddDownloadCateModel
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
        />
      )}
      {visibleSub && (
        <AddDownloadSubCateModel
          visible={visibleSub}
          setVisible={() => {
            setVisibleSub(!visibleSub);
          }}
        />
      )}
    </div>
  );
}

export default EditDownload;
