import { Button, DatePicker, Form, message, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { PostDataAuth } from "../../apiService/PostData";
import { apis } from "../../properties";
import moment from "moment";
import useFundContentDetails from "../../hooks/useFundContentDetails";

function DateUpdateModel({ visible, setVisible, selectedId }) {
  const [form] = Form.useForm();
  const [filterData] = useState({
    status: "all",
    lang_id: "en",
    id: selectedId,
  });
  const fundContentDetails = useFundContentDetails(filterData);
  const [isEnable, setIsEnable] = useState(false);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  useEffect(() => {
    if (fundContentDetails) {
      form.setFieldsValue({
        custom_date:
          fundContentDetails.custom_date &&
          moment(fundContentDetails.custom_date),
      });
      setIsEnable(fundContentDetails.custom_date !== null ? true : false);
    }
    // eslint-disable-next-line
  }, [fundContentDetails]);

  const submitForm = () => {
    form.validateFields().then((values) => {
      PostDataAuth(apis.FUND_CONTENTS_UPDATE, {
        ...fundContentDetails,
        id: selectedId,
        custom_date: isEnable
          ? moment(values.custom_date).format("YYYY-MM-DD")
          : null,
      }).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          message.success(responseJson.message);
          setVisible(false);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
        }
      });
    });
  };

  return (
    <Modal
      title="Update Date"
      centered
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
    >
      <div className="step_model_body">
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item label="Custom Date Enable" name="custom_date_enable">
            <Switch
              checked={isEnable}
              onChange={(checked) => setIsEnable(checked)}
            />
          </Form.Item>
          {isEnable && (
            <Form.Item label="Select Custom Date" name="custom_date">
              <DatePicker
                size="large"
                placeholder="Select Date"
                className="full_width"
                disabled={!isEnable}
              />
            </Form.Item>
          )}
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default DateUpdateModel;
